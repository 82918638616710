#referFriends {
  .banner-container {
    position: relative;
    text-align: center;
    .banner-image {
      width: 100%;
    }
  }
  h3 {
    margin-bottom: 28px;
    text-align: center;
    font-size: 28px;
  }
  .content-box {
    .intro-text {
      color: $primary;
      text-align: center;
      font-size: 17px;
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    .image-dash {
      margin-bottom: 50px;
    }

    .referee-section {
      margin: 0 5%;
      .box {
        border-radius: 6px;
        background-color: $white;
        position: relative;
        margin: 0 0 20px 0;
        padding: 40px 28px 40px 28px;
        .text-blue {
          font-size: 17px;
          color: $blue;
          line-height: 1.2;
          z-index: 2000;
          position: relative;
        }
        .text-grey {
          margin-top: 20px;
          font-size: 14px;
          color: rgb(160, 160, 160);
          line-height: 1.2;
        }
        .link-wrapper {
          margin-top: 20px;
          .btn-blue {
            border-radius: 4px;
            background-color: $blue;
            width: 100%;
            height: 40px;
            padding: 0;
          }
        }
        .info-wrapper {
          border-width: $border-width;
          border-color: rgb(39, 5, 109);
          border-radius: 6px;
          background-color: $light-gray;
          height: 104px;
          margin-top: 30px;
          .info-label {
            display: inline-block;
            padding: 5px 8px;
            background-color: $blue;
            color: white;
            min-width: 86px;
            text-align: center;
            border-bottom-right-radius: 5px;
          }
          .info-image {
            margin-top: 11px;
            @include rtl-sass-prop(margin-left, margin-right, 1px);
          }
          .info-number {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            .number-wrapper {
              position: relative;
              .txt {
                position: absolute;
                top: 7px;
                @include rtl-sass-prop(left, right, 26px);
                color: #fff;
                font-size: 36px;
              }
              img {
                margin: 0 8px;
              }
            }
          }
        }
      }

      .total-box {
        margin-bottom: 0;
      }

      .mobile-box {
        border-radius: 6px;
        background-color: $white;
        position: relative;
        margin: 0 0 20px 0;
        padding: 40px 0 30px 0;
        height: 100%;
        .mobile-image {
          text-align: center;
          img {
            width: 60%;
            margin-top: 30px;
            z-index: 1;
          }
        }
        .text-blue {
          padding-left: 28px;
          padding-right: 28px;
        }
        .sns-wrapper {
          margin: 0 auto;
          display: table;
          a {
            padding: 5px 9px;
          }
        }
      }
    }

    .table-section {
      margin: 45px 5% 30px;
      .table-redeem {
        height: 336px;
      }
      .table-box {
        border: 1px solid #dddbe9;
        border-radius: 6px;
        border-top: none;
        /deep/.el-table {
          .table-header {
            color: #f5f5f9;
            background: #014abd;
            background-clip: border-box;
            @include rtl-sass-prop(border-right, border-left, 10px solid $light-gray);
          }
          th:nth-last-child(2) {
            @include rtl-sass-prop(border-right, border-left, none);
          }
        }
        /deep/.el-table {
          border-radius: 6px;
        }

        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
          background: none;
          background-color: rgba(144, 147, 153, 0.3);
          border-radius: 10px;
        }

        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
          height: 6px;
        }
      }
      .input-box {
        height: 100%;
        border-radius: 6px;
        background-color: $white;
        position: relative;
        border: 1px solid #dddbe9;
        .background {
          height: 70px;
          border-radius: 6px 6px 0 0;
          background-color: $blue;
          .background-text {
            color: white;
            text-align: center;
            padding: 15px;
          }
        }
        .content-wrapper {
          .button-wrapper {
            display: flex;
            justify-content: center;
            .btn-red {
              width: 100%;
              margin: 20px 10%;
              padding: 12px 0;
              font-size: 14px;
              &.is-disabled {
                background-color: #c0c4cc;
                color: #fff;
              }
              &.is-disabled:hover {
                background-color: #c0c4cc;
              }
            }
          }
          .content {
            color: #a0a0a0;
            font-size: 12px;
            margin: 10% 10% 0;
            word-break: break-word;
            /deep/ .el-input__inner {
              font-size: 14px;
              line-height: 1.2;
              background-color: #dcdcdc;
              color: $blue;
            }
          }
        }
      }
    }

    .footer {
      max-width: 1215px;
      margin: 65px auto 0;

      img {
        display: block;
        margin-bottom: 30px;
      }

      span {
        font-size: 15px;
        color: rgb(160, 160, 160);
        line-height: 1.2;
      }
    }
  }
}

@media (max-width: 992px) {
  #referFriends {
    .content-box {
      .table-section {
        .input-box {
          .content-wrapper {
            .content {
              margin: 3% 10% 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 990px) {
  #referFriends {
    .content-box {
      .table-section {
        .table-redeem {
          margin-top: 25px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #referFriends {
    .content-box {
      .table-section {
        .table-box {
          /deep/.el-table {
            th:nth-last-child(2) {
              @include rtl-sass-prop(border-right, border-left, unset);
            }
          }
        }
        .content {
          margin: 5% 10% 0 !important;
        }
        .padContent {
          margin: 5% 10% 0 !important;
        }
      }
      .referee-section {
        .mobile-box {
          margin-top: 25px;
          .sns-wrapper {
            margin: 0 auto;
          }
        }
        .box {
          .info-wrapper {
            .info-label {
              min-width: 66px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  #referFriends {
    .content-box {
      .referee-section {
        .link-wrapper {
          .btn-blue {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
