.voucher-wrapper {
  display: flex;
  justify-content: center;

  .voucher {
    position: relative;

    img {
      @include rtl-sass-prop-sd(transform, scaleX(-1));
    }

    .voucher-text {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      @include rtl-sass-prop(right, left, 0);
      display: flex;

      .amount,
      .status {
        height: 65%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        display: -webkit-flex; /* Safari */
        align-items: center; /* Safari */

        p {
          color: $blue;
          line-height: 1.2;
          text-align: center;
          margin: 0 auto;
        }
      }

      .amount {
        width: 33%;

        p {
          font-size: 32px;
        }
      }

      .status {
        width: 66%;

        p {
          font-size: 14px;
          max-width: 60%;
        }
      }
    }
  }

  &:nth-child(3) .voucher .voucher-text {
    .amount,
    .status {
      height: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .voucher-wrapper .voucher .voucher-text {
    .amount p {
      font-size: 28px;
    }
    .status p {
      font-size: 12px;
    }
  }
}

@media (max-width: 550px) {
  .voucher-wrapper .voucher .voucher-text {
    .amount p {
      font-size: 12px;
    }
    .status p {
      font-size: 10px;
    }
  }
}
