#referFriends {
  .content-box {
    .unuseful-section {
      padding: 40px 0 100px 0;
      margin: 0 15%;
      .stepline_wrapper {
        padding-right: 0 !important;
        .stepline {
          width: 100%;
        }
      }
    }
    .referrers_section {
      margin: 0 15%;
      .input-box {
        height: 340px;
        border-radius: 6px;
        background-color: $white;
        position: relative;
        .background {
          height: 70px;
          border-radius: 8px 8px 0 0;
          background-color: $blue;
          .background-text {
            color: white;
            text-align: center;
            padding: 15px;
          }
        }
        .content-wrapper {
          .button-wrapper {
            display: flex;
            justify-content: center;
            .btn-red {
              width: 100%;
              margin: 20px 10%;
              padding: 12px 0;
              font-size: 14px;
              &.is-disabled {
                background-color: #c0c4cc;
                color: #fff;
              }
              &.is-disabled:hover {
                background-color: #c0c4cc;
              }
            }
          }
          .content {
            color: #a0a0a0;
            font-size: 12px;
            margin: 10% 10% 0;
            word-break: break-word;
            /deep/ .el-input__inner {
              font-size: 14px;
              line-height: 1.2;
              background-color: #dcdcdc;
              color: $blue;
            }
          }
        }
      }
      .box {
        height: 320px;
        border-radius: 6px 6px 0 0;
        background-color: $white;
        position: relative;

        .top {
          .shadow {
            position: absolute;
            top: 23px;
            left: -8px;
            content: url(~@/assets/images/autochartist/shadow.png);
          }

          .title {
            position: absolute;
            top: 23px;
            left: -8px;
            padding: 0 50px 0 30px;
            display: inline-block;
            background: rgb(160, 160, 160);
            height: 40px;
            line-height: 40px;
            -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
            -moz-clip-path: polygon(0 0, 0% 100%, 100% 0);
            -ms-clip-path: polygon(0 0, 0% 100%, 100% 0);
            clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
            font-size: 17px;
            color: $light-gray;
          }
        }

        .bottom {
          overflow: hidden;
          position: relative;
          height: 100%;
          width: 100%;
          padding: 20px 20px;
          justify-content: space-between;
          flex-direction: column;
          display: flex;

          .placeholder {
            margin: 60px 0 0;
          }
          .content {
            display: flex;
            justify-content: center;
          }
          .blue_info {
            color: #a0a0a0;
            text-align: center;
            font-size: 14px;
            line-height: 1.2;
            padding: 12px 0;
          }
        }
        &:hover {
          border-style: 1px solid $border-color;
          box-shadow: 0.572px 2.945px 4px 0px rgba(19, 33, 116, 0.15);
        }
        .background {
          height: 20px;
          border-radius: 0 0 8px 8px;
          background-color: #a0a0a0;
        }
      }

      .active_box {
        .top {
          .shadow {
            content: url(~@/assets/images/autochartist/shadow_active.png);
          }
          .title {
            background-color: $future-blue;
          }
        }
        .bottom {
          .blue_info {
            color: $primary;
            text-align: center;
            font-size: 14px;
            line-height: 1.2;
            padding: 20px 0;
          }
        }
        .background {
          background-color: $primary;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  #referFriends {
    .content-box {
      .referrers_section {
        margin: 0 5%;
        .input-box {
          .content {
            margin: 5% 10% 0 !important;
            &.padContent {
              margin: 2% 10% 0 !important;
            }
          }
        }
        .box {
          margin-bottom: 50px;
        }
        margin-bottom: 50px;
        .padContent {
          margin: 8% 10% 0 !important;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  #referFriends {
    .content-box {
      .unuseful-section {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  #referFriends {
    .content-box {
      .referrers_section {
        margin: 0 20%;
        margin-bottom: 50px;

        .input-box {
          .content {
            margin: 10% 10% 0 !important;
            &.padContent {
              margin: 4% 10% 0 !important;
            }
          }
        }
        .box {
          margin-bottom: 50px;
        }
      }
    }
  }
}

@media (max-width: 460px) {
  #referFriends {
    .content-box {
      .referrers_section {
        margin: 0 20%;
        margin-bottom: 50px;
        .input-box {
          .content {
            margin: 10% 10% 0 !important;
            &.padContent {
              margin: 8% 10% 0 !important;
            }
          }
        }
        .box {
          margin-bottom: 50px;
        }
      }
    }
  }
}
