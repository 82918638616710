#referFriends {
  .content-box {
    .voucher-section {
      margin: 50px 5% 70px;
      .box {
        border-radius: 6px;
        background-color: $white;
        position: relative;
        margin: 0 0 20px 0;
        padding: 50px 40px 50px 40px;
        border: 1px solid #dddbe9;
        .image-Wrapper {
          display: flex;
          justify-content: center;
          .text-wrapper {
            position: relative;
            .txt {
              position: absolute;
              top: 78%;
              @include rtl-sass-prop(left, right, 10%);
              color: #fff;
              font-size: 12px;
              line-height: 1.2;
              text-transform: unset;
            }
            .txt2 {
              position: absolute;
              top: 78%;
              @include rtl-sass-prop(left, right, 80%);
              color: #fff;
              font-size: 12px;
              line-height: 1.2;
              text-transform: unset;
            }
            img {
              margin: 40px 8px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  #referFriends {
    .content-box {
      .voucher-section {
        .box {
          .image-Wrapper {
            .text-wrapper {
              .txt {
                position: absolute;
                top: 73%;
                @include rtl-sass-prop(left, right, 10%);
                color: #fff;
                font-size: 12px;
                line-height: 1.2;
                text-transform: unset;
              }
              .txt2 {
                position: absolute;
                top: 73%;
                @include rtl-sass-prop(left, right, 80%);
                color: #fff;
                font-size: 12px;
                line-height: 1.2;
                text-transform: unset;
              }
              img {
                margin: 40px 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #referFriends {
    .content-box {
      .voucher-section {
        .box {
          .image-Wrapper {
            .text-wrapper {
              .txt {
                position: absolute;
                top: 78%;
                @include rtl-sass-prop(left, right, 10%);
                color: #fff;
                font-size: 14px;
                line-height: 1.2;
                text-transform: unset;
              }
              .txt2 {
                position: absolute;
                top: 78%;
                @include rtl-sass-prop(left, right, 80%);
                color: #fff;
                font-size: 14px;
                line-height: 1.2;
                text-transform: unset;
              }
              img {
                margin: 40px 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  #referFriends {
    .content-box {
      .voucher-section {
        .box {
          padding: 10px;
          .image-Wrapper {
            .text-wrapper {
              .txt {
                position: absolute;
                top: 74%;
                @include rtl-sass-prop(left, right, 10%);
                color: #fff;
                font-size: 12px;
                line-height: 1.2;
                text-transform: unset;
              }
              .txt2 {
                position: absolute;
                top: 74%;
                @include rtl-sass-prop(left, right, 80%);
                color: #fff;
                font-size: 12px;
                line-height: 1.2;
                text-transform: unset;
              }
              img {
                margin: 40px 8px;
              }
            }
          }
        }
      }
    }
  }
}
